// Variables

$white: #fff !default;
$gray-light: #f2f2f2;
$gray: rgba(0,0,0,.1);
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #161616 !default;

$primary: #D8262E;
$dark-primary: #c52a2d;
$secondary: lighten(#00963e, 5%);
