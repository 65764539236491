#mainNav {
  min-height: 56px;
  background-color: $primary;
  .navbar-toggler {
    font-size: 120%;
    padding: 0.5rem;
    color: $primary;
    border: none;
    &:focus {
      outline: none;
    }
  }
  .navbar-brand {
    padding: .5rem 0;
    height: 80px;
    img {
      max-height: 60px;
    }
  }
  .nav-link {
    -webkit-transition: none;
    transition: none;
    padding: 0;
    color: $white;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      color: fade-out($white, .1);
    }
    &:active {
      color: $white;
    }
    span {
      display: none;
      visibility: hidden;
    }
  }
  .login i {
    font-size: 2rem;
  }
  .navbar-nav {
    .nav-item {
      &:hover {
        color: fade(white, 80%);
        outline: none;
        background-color: transparent;
      }
      &:active,
      &:focus {
        outline: none;
        background-color: transparent;
      }
    }
  }
  @media (min-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
    background-color: $primary;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    .navbar-brand {
      padding: .5rem 0;
      // margin-top: 1rem;
      color: fade-out($white, .5);
      height: 100px;
      img {
        max-height: 80px;
      }
    }
    .login i {
      font-size: 1.5rem;
    }
    .nav-link {
      -webkit-transition: none;
      transition: none;
      padding: 0;
      color: $white;
      &:hover {
        color: fade-out($white, .1);
      }
      &:active {
        color: $white;
      }
      span {
        display: inline;
        visibility: visible;
      }
    }
    &.navbar-shrink {
      background-color: $primary;
      -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
      .navbar-brand {
        color: $black;
        margin-top: 0;
        height: 80px;
        background: $primary;
        img {
          max-height: 60px;
        }
      }
      .nav-link {
        color: $white;
        padding: .8rem 0 0 0;
        border-bottom: 0.25rem solid transparent;
        &:hover {
          color: fade-out($white, .1);
        }
        &:active {
          color: $white;
          outline: none;
          border-bottom: 0.25rem solid $primary;
        }
      }
    }
  }
}


.back-listado {
  display: none;
}

.ficha-producto .back-listado, .pagina-proyectos .back-listado {
  display: block;
}