.btn {
  cursor: pointer;
  padding: .8rem 1rem;
  font-family: 'Lato', sans-serif;
  font-size: 80%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .1rem;
  border-radius: 0;
  border: 0;
}

.btn-primary {
  color: white !important;
  background-color: $primary;
  &:hover {
    background-color: darken($primary, 10%);
  }
  &:focus {
    background-color: darken($primary, 10%);
    color: white;
    box-shadow: none;
  }
  &:active {
    background-color: darken($primary, 15%) !important;
  }
}

.btn-light {
  color: $gray-900 !important;
  background-color: $gray-200;
}

.btn-white {
  color: $primary !important;
  background-color: $white;
  &:hover {
    color: $primary !important;
    background-color: rgba($white,.9);
  }
  &:focus {
    background-color: $white;
    color: $primary !important;
    box-shadow: none;
  }
  &:active {
    color: darken($primary, 15%);
    background-color: rgba($white,.85) !important;
  }
}
