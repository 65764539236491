#product-header {
  background-color: $white;
  border-bottom: 1px solid $gray-200;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  .nav-info {
    padding: 1rem 0 .5rem !important;
    border-bottom: 1px solid $gray-100;
    i {
      font-size: 1.2rem;
      color: $gray-600;
    }
    span {
      color: $gray-600;
    }
    a:hover {
      i, span {
        color: $primary;
      }
    }
  }
  .product-header-info {
    @media (max-width: 550px) {
      > .d-flex > div {
        display: block !important;
        .product-type {
          margin-left: 0 !important;
        }
      }
    }
    .product-name {
      color: $primary;
      font-size: 1.5rem;
      margin: 0;
    }
    .product-type {
      text-transform: uppercase !important;
      font-size: 1.4rem;
      margin: 0;
      @media (max-width: 550px) {
        margin-top: 0.5rem;
        span {
          display: none;
        }
      }
    }
    .botoneras-producto > * {
      cursor: pointer;
    }
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .btn {
      font-size: .7rem;
      padding: .5rem .9rem ;
    }
    .save-icon {
      color: $gray-700;
      &:hover {
        cursor: pointer;
        opacity: .8;
      }
      &:active,
      &:focus {
        outline: 0px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
  .nav, .nav-tabs {
    border: none;
    background-color: $white;
    border-top: 1px solid $gray-100;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .nav-item {
      background-color: $white;
      border-bottom: 1px solid $gray-200;
      .nav-link {
        cursor: pointer;
        padding: 1rem;
        color: $gray-800;
        border: none;
        font-size: .8rem;
        font-weight: 400;
        text-transform: uppercase;
        border-bottom: 3px solid transparent;
        &:hover {
          color: $gray-800;
          border: none;
          border-bottom: 3px solid $primary;
          background-color: $gray-100;
        }
      }
      .nav-link.active {
        color: $primary;
        border: none;
        font-weight: 700;
        border-bottom: 3px solid $primary;
        &:hover {
          background-color: $white;
        }
      }
      .nav-link.disabled {
        color: rgba(black, .25);
      }
    }
  }
  @media(max-width: 768px) {
    .product-header-info {
      .product-title {
        font-size: 1.25rem;
        padding-bottom: .8rem;
      }
    }
    .nav, .nav-tabs {
      display: none;
      visibility: hidden;
    }
  }
}
.product {
  padding: 1rem 0;
  background-color: $gray-light;
  .card {
    padding: 1rem;
    margin: 1rem 0;
    border: none;
    border-radius: 0;
    // -webkit-box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    // box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    border: 1px solid $gray-200;
  }
}

.wrapperGrafica {
  max-width: 500px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}


.tablaVersiones {
  a {
    color: #000;
    font-weight: bold;
    &:hover {
      color: $primary
    }
  }
  .referenciaDestacada {
    background-color: rgba(255,0,0,0.1);
  }
}

.descriptionProducto  {
  ul {
    margin-bottom: 0.5rem;
  }
  p {
    font-weight: bolder;
    margin-top: 1.5rem;
  }
}


#graficasValoresLlenado canvas {
  width: 100%;
}


#modal-icons-legend {
  text-align: center;
  img {
    width: 100px !important;
    height: 100px !important;
    margin: 0 auto 0.5rem auto;
    + p {
    }
  }
}

.btn-open-lengend-icons {
  cursor: pointer;
  color: $primary !important;
}

.view-legends-info {
  text-align: center;
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-top: 5px
}