.projects-section {
  padding: 1rem 0;
  background-color: $gray-light;
  .card {
    padding: 1rem;
    margin: 1rem 0;
    border: none;
    border-radius: 0;
    border: 1px solid $gray-200;
    #listado-proyectos {
      .card-header {
        cursor: pointer;
        .icon-collapse {
          color: $primary;
          vertical-align: bottom;
          font-size: 1.4rem;
          margin-right: 0.4rem;
          -webkit-transition: 0.5s;
          transition: 0.5s;
        }
        &[aria-expanded=false] .icon-collapse {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
      .card-body .media {
        img {
          max-width: 100px;
          margin-right: 1rem;
          border: 1px solid #f8f9fa;
        }
        a {
          color: #000;
          > span {
            color: $primary;
          }
        }
      }
      .listado-productos-proyecto > div {
        position: relative;
        + div {
          padding-top: 1rem;
          margin-top: 1rem;
          border-top: 1px solid #eee;
        }
      }
      .card-header .right {
        float: right;
        color: #000;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
        &[aria-disabled=true] {
          cursor: wait;
        }
        margin-left: 10px;
        &.solicita-presupuesto {
          background-color: $primary;
          padding: 0.2rem 0.5rem;
          font-size: 0.8rem;
          color: #fff;
          letter-spacing: 0.01rem;
        }
        &.solicitado {
          cursor: default;
          background-color: #555;
        }
      }
      .form-control {
        width: calc(100% - 2rem);
        display: inline-block;
      }
      .add-project {
        margin-top: 0.4rem;
        float: right;
      }
    }
  }
}

