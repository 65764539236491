@import 'variables.scss';
@import 'navbar.scss';
@import 'masthead.scss';
@import 'results.scss';
@import 'product.scss';
@import 'projects.scss';
@import 'buttons.scss';
@import 'modal.scss';
@import 'progressbar.scss';
@import 'cookies.scss';

body {
  font-family: 'Lato', sans-serif;
  // letter-spacing: 0.0625em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

.text-muted {
  color: $gray-700 !important;
}

footer {
  padding: 5rem 0 1rem;
  background-color: $gray-light;
  a {
    color: #6c757d;
    &:hover {
      color: $gray-900;
    }
  }
  .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    text-align: center;
    @media(min-width: 992px) {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  }
  p:first-child + p a {
    cursor: pointer;
  }
}

.loadingView {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  + * {
    opacity: 0;
    &.wrapper-buscador {
      opacity: 1;
      #filter > *, #search-results .container {
        opacity: 0;
      }
    }
  }
}

.locale-selector {
  background-color: transparent ;
  border: none;
  color: #ffffff;
  outline: transparent none;
}
.locale-selector:focus{
  background-color: transparent ;
  border: none;
  outline: transparent none;

}