.modal {
  padding: 0 !important;
  .modal-header {
    position: absolute;
    z-index: 1;
    right: 0;
    border-bottom: 0;
    button {
      padding-right: 1rem;
      &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
      }
    }
    @media(min-width: 992px) {
      button {
        padding-top: 1.5rem;
        padding-right: 2rem;
      }
    }
  }
  .btn {
    cursor: pointer;
  }
  .form-control {
    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
      border-color: $gray-600;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }
}
.modal-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../img/aiscan-fabrica.jpg');
}
.overlay {
  padding: 2.5rem 2rem;
  height: 100%;
  background-color: rgba($primary, .8);

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .modal-title, .lead {
    color: $white;
    text-align: center;
    margin-bottom: .5rem;
  }
  .lead {
    font-weight: 400;
  }
  @media(min-width: 992px) {
    padding: 3rem;
  }
}
.modal-form {
  padding: 2rem;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .modal-title {
    color: $primary;
    margin-bottom: 0;
    font-weight: 300;
  }
  .form-check-label {
    color: $gray-800;
  }
  @media(min-width: 992px) {
    padding: 5rem;
  }
}
.btn-lg {
  width: 60%;
  margin-top: 1.5rem;
}
#LoginModal {
  .modal-form {
    padding: 2rem;
    @media(min-width: 992px) {
      padding: 4rem;
    }
  }
  a {
    font-weight: 400;
    color: $primary;
    cursor: pointer;
  }
}
