.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 20rem;
  padding: 0;
  // margin-top: 80px;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(50%, $primary), color-stop(0, $gray-light));
  background: linear-gradient(180deg,$primary 50%, $gray-light 0);
  h1 {
    font-family: 'Lato', sans-serif;
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.8rem;
    background: -webkit-linear-gradient(fade-out(white, 0.1), fade-out(white, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
  h2 {
    max-width: 20rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $primary;
  }
  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media(min-width: 992px) {
    padding: 0;
    // margin-top: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    h1 {
      font-size: 6.5rem;
      line-height: 6.5rem;
      letter-spacing: 0.8rem;
    }
    h2 {
      max-width: 30rem;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
small {
  color: $black;
  font-weight: 300;
  font-size: 1.1rem;
  @media(min-width: 992px) {
    font-size: 1.1rem;
  }
}


#filter {
  padding: 1rem;
  border: 1px solid $gray-200;
  border-radius: 0;
  // -webkit-box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
  // box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
  .form-item {
    margin-top: 1rem;
    .form-item-title {
      font-size: .9rem;
      font-weight: 400;
      color: $gray-700;
    }
    .form-check {
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      img {
        border: 3px solid $white;
      }
      &:hover {
        cursor: pointer;
        img {
          opacity: .5;
          border: 3px solid $white;
        }
      }
      &:focus, &:active {
        img {
          opacity: 1;
          border: 3px solid $primary;
        }
      }
      input[type=radio]:checked + img {
        opacity: 1;
        border: 3px solid $primary;
      }
    }
    .form-fields {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .form-group {
        label {
          font-size: .8rem;
          font-weight: 300;
          color: $black;
        }
        .form-control:focus {
          outline: 0px !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          border-color: $gray-600;
        }
        ::-webkit-input-placeholder {
          color: $gray-500;
          font-weight: 300;
        }
        ::-ms-input-placeholder {
          color: $gray-500;
          font-weight: 300;
        }
        ::placeholder {
          color: $gray-500;
          font-weight: 300;
        }
      }
      button.add {
        background: none;
        border: none;
        padding: 0;
        i {
          font-size: 1.8rem;
          color: $secondary;
          padding-right: .25rem;
        }
        span {
          opacity: .8;
          font-size: .8rem;
          display: none;
          visibility: hidden;
        }
        &:hover {
          cursor: pointer;
          span {
            opacity: 1;
          }
        }
        &:focus {
          outline: 0px;
        }
      }
      button.remove {
        margin-top: 1.25rem;
        background: none;
        border: none;
        padding: 0;
        i {
          font-size: 1.8rem;
          color: $gray-500;
          padding-right: .25rem;
        }
        span {
          opacity: .5;
          font-size: .8rem;
          display: none;
          visibility: hidden;
        }
        &:hover {
          cursor: pointer;
          i {
            color: lighten($black, 25%);
          }
          span {
            opacity: .8;
          }
        }
        &:focus {
          outline: 0px;
        }
      }
      label[for=ReserveInput] {
        font-size: 1.2rem;
        padding: .25rem 0 0 .5rem;
      }
    }
  }
  .cables-section {
    .form-group {
      margin-right: .5rem !important;
    }
    @media(min-width: 1200px) {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: flex-end;
      > div {
        -webkit-flex-shrink: 1;
        flex-shrink: 1;
        -webkit-flex-basis: calc(33% - 1rem);
        flex-basis: calc(33% - 1rem);
      }
      .form-item:nth-child(3n), .form-item:nth-child(3n - 1) {
        .add {
          margin-bottom: 1rem;
        }
      }
    }
    @media(min-width: 992px) and (max-width: 1199px) {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      align-items: flex-end;
      > div {
        -webkit-flex-shrink: 1;
        flex-shrink: 1;
        -webkit-flex-basis: calc(50% - 1rem);
        flex-basis: calc(50% - 1rem);
      }
      .form-item:nth-child(2n), .form-item:nth-child(2n - 1) {
        .add {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .checkbox-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .form-check-input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-left: 5px !important;
    }
  }
  .rangeslider-section {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    padding-top: 1rem;

    @media(min-width: 768px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-top: 0;
    }

    @media(min-width: 992px) {
      .form-item {
        width: 50%;
      }
    }
  }
  .rangeslider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media(min-width: 768px) {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
    #RangeQuantity {
      width: 22%;
      min-width: 50px;
    }
  }
}
@media(min-width: 992px) {
  #filter {
    margin-top: 1rem;
    .form-item {
      padding-right: 1rem;
      .form-fields {
        button.remove,
        button.add {
          span {
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            visibility: visible;
          }
          i {
            font-size: 1.3rem;
          }
        }
      }
    }
    .cables-section {
      .form-group:first-child {
        width: 45% !important;
        margin-right: .5rem !important;
      }
      .form-group:nth-child(2) {
        width: 25% !important;
        margin-right: .5rem !important;
      }
      .form-group {
        width: 33% !important;
        margin-right: .5rem !important;
      }
    }
    .checkbox-images {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;

      label {
        font-size: .9rem;
        font-weight: 400;
        color: $gray-700;
      }
    }
    .form-check {
      margin-right: 1rem;
    }
    .filter-section {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
}

@media(max-width: 992px) and (min-width: 400px) {
  #filter .cables-section .form-group {
    width: 45% !important;
  }
}

@media(max-width: 399px) and (min-width: 350px) {
  #filter .cables-section .form-group:first-child {
    width: 55% !important;
  }
  #filter .cables-section .form-group:nth-child(2) {
    width: 25% !important;
  }
}

@media(max-width: 350px) {
  #filter .cables-section .form-fields {
    display: block;
    overflow: auto;
  }
  #filter .cables-section .form-group:nth-child(2) {
    float: left;
    width: calc(100% - 3rem);
  }
  #filter .form-item .form-fields button.remove {
    margin-top: 2.2rem;
  }
}

.wrap-tipo-bandeja {
  position: relative;
  input[type=radio] {
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    z-index: 1;
    opacity: 0;
  }
}

.error-message {
  border-left: 3px solid $primary;
  padding: 5px 5px 5px 10px;
  background-color: rgba(255,0,0,0.05);
}

.errorField {
  border-color: $primary;
}
