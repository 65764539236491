#bar_cookies {
  z-index: 100000005;
  position: fixed;
  bottom: 0px;
  left: 0px;
  text-align: center;
  padding: 12px;
  font-size: 14px;
  background: #f0f0f0;
  color: #505050;
  text-decoration: none;
  margin: 0px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  .aceptar {
    background: #303030;
    color: #FFF;
    text-decoration: none;
    font-size: 12px;
    padding: 6px 8px;
    margin-left: 15px;
    cursor: pointer;
  }
  .leer-mas {
    margin-left: 15px;
    text-decoration: none;
    color: #404040;
    font-size: 12px;
  }
}


@media only screen and (max-width: 1030px) {

  #bar_cookies {
    padding: 12px 1% 45px !important;
    width: 98% !important;
    .aceptar {
      position: absolute;
      right: 50%;
      margin-right: 5px;
      bottom: 7px;
    }
    .leer-mas {
      background-color: #ddd;
      bottom: 7px;
      left: 50%;
      margin-left: 5px !important;
      padding: 6px;
      position: absolute;
    }
  }

}