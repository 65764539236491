#search-results {
  padding: 3rem 0;
  background-color: $gray-light;
  position: relative;
  .product-card {
    padding: 1rem;
    margin: .5rem 0;
    border: 1px solid $gray-200;
    border-radius: 0;
    background-color: $white;
    // -webkit-box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    // box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    .product-type {
      text-transform: uppercase;
      font-size: .8rem;
      color: #000;
    }
    .product-name {
      color: $primary;
      font-size: 1.5rem;
    }
    .card-body {
      .media {
        .img-results {
          width: 180px;
          max-width: 50%;
          margin-right: 1rem;
          border: 1px solid $gray-100;
          a {
            text-transform: uppercase;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}
.pagination {
  margin-top: 1rem;

  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  .page-item {
    .page-link {
      cursor: pointer;
      color: $gray-800;
      border-radius: 0;
      border: 1px solid $gray-200;
      &:hover {
        color: $primary;
        background-color: $gray-100;
      }
      &:focus {
        outline: 0px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
    }
  }
  .page-item.active {
    .page-link {
      color: $white;
      border: 1px solid #dee2e6;
      background-color: $primary;
    }
  }
  .page-item.disabled {
    .page-link {
      color: rgba(black, .25);
    }
  }
}
